import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'SearchClient',
          path: '/kiosk',
          component: () => import('@/views/client/SearchClient'),
        },
        // Pages
        {
          name: 'Client SignUp',
          path: 'client_signup',
          component: () => import('@/views/dashboard/pages/ClientSignup'),
        },
        {
          name: 'Manage Clients',
          path: 'pages/clients',
          component: () => import('@/views/dashboard/pages/ManageClients'),
        },
        {
          name: 'Manage Users',
          path: 'pages/users',
          component: () => import('@/views/dashboard/pages/ManageUsers'),
        },
        {
          name: 'Manage Packages',
          path: 'pages/packages',
          component: () => import('@/views/dashboard/pages/ManagePackages'),
        },
        {
          name: 'Access List',
          path: 'pages/access_list',
          component: () => import('@/views/dashboard/pages/AccessList'),
        },
        {
          name: 'Facility List',
          path: 'pages/facilities',
          component: () => import('@/views/dashboard/pages/FacilityList'),
        },
        {
          name: 'Class Category',
          path: 'pages/class_category',
          component: () => import('@/views/dashboard/pages/CCategory'),
        },
        {
          name: 'Facility Category',
          path: 'pages/facility_category',
          component: () => import('@/views/dashboard/pages/FCategory'),
        },
        {
          name: 'Classes',
          path: 'pages/classes',
          component: () => import('@/views/dashboard/pages/classPages/Classes'),
        },
        {
          name: 'Bookings',
          path: 'pages/bookings',
          component: () => import('@/views/dashboard/pages/Bookings'),
        },
        {
          name: 'Branch List',
          path: 'pages/branches',
          component: () => import('@/views/dashboard/pages/BranchList'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
        {
          name: '',
          path: 'login',
          component: () => import('@/views/Home'),
        },
      ],
    },
  ],
})
