/* eslint-disable */
import axios from 'axios'

 const genBill = ({ commit, state, dispatch }, payload) => {
   const TransData = {
        customer_id: 0,
        outlet_id: payload.outlet_id,
        company_id: payload.company_id,
        user_id: payload.user_id,
        pres_id: 0,
        sale_status: 1,
      }

      axios.post(payload.apiUrl.singleSaleTransaction, TransData)
        .then(function (response) {
          // eslint-disable-next-line camelcase
          const sale_trans_id = response.data[1].sale_trans_id
          doSalePayment({ ...payload, trans_id: sale_trans_id })
        })
        .catch(function (error) {
            this.err.push(error)
        })
}

const doSalePayment = (payload) => {
  const r = Math.floor(Math.random() * 10)

  // eslint-disable-next-line camelcase
  const invoice_no = 'P' + payload.company_name.charAt(0) + '-' + r + payload.trans_id

  const PayData = {
    customer_id: 1,
    patient_id: payload.patient_id,
    outlet_id: payload.outlet_id,
    company_id: payload.company_id,
    user_id: payload.user_id,
    visit_id: payload.visit_id,
    sale_status: 0,
    sale_transaction_id: payload.trans_id,
    payment_type: 'PSEUDO',
    payment_amount: payload.payment_amount,
    return_amount: 0,
    amount_tendered: payload.amount_tendered,
    amount_due: payload.amount_due,
    change_due: 0,
    total_items: 1,
    invoice_no: invoice_no,
    session: 0,
    momo_no: 0,
    cheque_no: 0,
    pseudo: 1,
    visa_bank: 0,
    pay_comments: 0,
    date_received: new Date().toISOString(),
  }

  axios.post(payload.apiUrl.singleSalePayment, PayData)
    .then(function (response) {
      // eslint-disable-next-line camelcase
      const sale_payment_id = response.data[1].sale_payment_id
      doSaleItems({ ...payload, payment_id: sale_payment_id, invoice_no: invoice_no })
    })
    .catch(function (error) {
      this.err.push(error)
    })
}

const doSaleItems = (payload) => {
  // let apiUrl = state.config.api;

  const prodComplete = {
    customer_id: payload.patient_id,
    patient_id: payload.patient_id,
    node_service_id: payload.node_service_id,
    node_service_name: payload.node_service_name,
    outlet_id: payload.outlet_id,
    company_id: payload.company_id,
    panel_id: payload.panel_id,
    test_id: payload.test_id,
    user_id: payload.user_id,
    sale_status: 0,
    visit_id: payload.visit_id,
    visit_type: payload.visit_type,
    rid: payload.rid,
    pack_size: 0,
    sale_transaction_id: payload.trans_id,
    sale_payment_id: payload.payment_id,
    product_id: '',
    product_name: '',
    quantity: 1,
    item_cost_price: payload.item_cost_price,
    item_selling_price: payload.item_cost_price,
    invoice_no: payload.invoice_no,
    discount_percentage: 0,
    tax: 0,
  }

  axios.post(payload.apiUrl.singleSaleItem, prodComplete)
    .then(function (response) {
      // run visit for here
    })
    .catch(function (error) {
      this.err.push(error)
      this.er_snackbar = true
    })
}

const checkTestSaleStatus = ({ commit, state }, payload) => {
  const apiUrl = state.config.api

  return new Promise((resolve, reject) => {
    axios.get(apiUrl.testSaleStatus + '/' + payload.id)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}

export { genBill, checkTestSaleStatus }
