/* eslint-disable */
import * as billing from './ActionModules/Billing'
import moment from 'moment'
// import mixins from '../mixins/index'
// import { eventBus } from '../main.js'

const axios = require('axios')

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  axios.defaults.headers.common.id_token = token
}

export default {
     // All Action Module routes
  genBill: billing.genBill,
  checkTestSaleStatus: billing.checkTestSaleStatus,
  countLabCompleted ({ state, commit }) {
    const apiUrl = state.config.api
    return new Promise((resolve, reject) => {
      axios.get(apiUrl.countLabCompleted)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

    // MEDINGIN ACTIONS SET

    fetchPackages ({ commit, state }, payload) {
        const apiUrl = state.config.api
        let err = [];

        axios.get(apiUrl.packages)
            .then(response => {
                const obj = response.data
                commit('setPackages', obj)
            }).catch(
            e => {
                err.push(e)
            },
        )
    },
    fetchFacilityCategories ({ commit, state }, payload) {
        const apiUrl = state.config.api
        axios.get(apiUrl.facilityCategory)
            .then(response => {
                const obj = response.data.categories
                commit('setFacilityCategories', obj)
            }).catch(
            e => {
                console.log(e);
            },
        )
    },

  fetchClassCategories ({ commit, state }, payload) {
        const apiUrl = state.config.api
        axios.get(apiUrl.classCategory)
            .then(response => {
                const obj = response.data.categories
                commit('setClassCategories', obj)
            }).catch(
            e => {
                console.log(e);
            },
        )
    },

  fetchTrainers ({ commit, state }, payload) {
        const apiUrl = state.config.api
        axios.get(apiUrl.getStaff)
            .then(response => {
              let userSet = [];
                const obj = response.data.users
              for(let app of obj) {
                userSet.push(
                  {...app,
                    name: app.firstName+" "+app.lastName
                  }
                )
              }
              console.log({ClassUserSet: userSet})
                commit('setTrainerList', userSet)
            }).catch(
            e => {
                console.log(e);
            },
        )
    },
  fetchClientList ({ commit, state }, payload) {
        const apiUrl = state.config.api
        axios.get(apiUrl.getClients)
            .then(response => {
              let userSet = [];
                const obj = response.data.users
              for(let app of obj) {
                userSet.push(
                  {...app,
                    name: app.firstName+" "+app.lastName
                  }
                )
              }
              console.log({ClassUserSet: userSet})
                commit('setClientList', userSet)
            }).catch(
            e => {
                console.log(e);
            },
        )
    },
  fetchFacilityList ({ commit, state }, payload) {
        const apiUrl = state.config.api
        axios.get(apiUrl.facilities)
            .then(response => {
                const obj = response.data.facilities
              console.log({FacilityClass: obj})
                commit('setFacilityList', obj)
            }).catch(
            e => {
                console.log(e);
            },
        )
    },
  fetchBranchList ({ commit, state }, payload) {
        const apiUrl = state.config.api
        axios.get(apiUrl.companies+'/'+payload)
            .then(response => {
                const obj = response.data.company.branches
              console.log({BranchList: obj})
                commit('setBranchList', obj)
            }).catch(
            e => {
                console.log(e);
            },
        )
    },

  fetchClassList ({ commit, state }, payload) {
        const apiUrl = state.config.api
        axios.get(apiUrl.classes)
            .then(response => {
                const obj = response.data.classes
              console.log({BranchList: obj})
                commit('setClassList', obj)
            }).catch(
            e => {
                console.log(e);
            },
        )
    },

  loadUserData({commit,state},payload){
    let apiUrl = state.config.api;
    const configData = {
      headers: { Authorization: `Bearer ${payload}` }
    };
    axios.get(apiUrl.me, configData)
      .then(response=>{
        console.log({userData:response.data});
        const obj = response.data;
        commit('setUserData',obj);
      }).catch(
      e => {
        console.log(e);
      }
    )
  },

    getPatientImg ({ commit, state }, payload) {
        const host = state.config.host

        if (payload === 'empty') {
            commit('setSaleCustomerImg', '/static/img/male.gif')
        } else if (payload === 'emergency') {
            commit('setSaleCustomerImg', '/static/img/emerg.png')
        } else if (payload === 'unknown') {
            commit('setSaleCustomerImg', '/static/img/unknown.png')
        } else {
            commit('setSaleCustomerImg', host + payload)
        }
    },

}
