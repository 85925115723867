export default {
    getApi (state) {
        return state.config.api
    },
    getHost (state) {
        return state.config.host
    },
    getHead (state) {
        return state.head
    },
  getSnack (state) {
    return state.snacky
  },
  getUserData (state) {
        return state.userData
    },
  getCoreAppBar (state) {
    return state.coreAppBar
  },
  getCoreSettings (state, payload) {
    return state.coreSettings
  },
  getCoreDrawer (state) {
    return state.coreDrawer
  },
  getCoreFooter (state) {
    return state.coreFooter
  },
  getActiveClient (state) {
    return state.activeClient
  },
  getPackages (state) {
    return state.subPackages
  },
  getFacilityCategories (state) {
    return state.facilityCategories
  },
  getClassCategories (state) {
    return state.classCategories
  },
  getTrainerList (state) {
    return state.trainerList
  },
  getClientList (state) {
    return state.clientList
  },
  getClassList (state) {
    return state.classList
  },
  getFacilityList (state) {
    return state.facilityList
  },
  getBranchList (state) {
    return state.branchList
  },
}
