export default {
   SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
    setHead (state, payload) {
        state.head = payload
    },
    setSnack (state, payload) {
        state.snacky = payload
    },
    setUserData (state, payload) {
     state.userData = payload
    },
    setUserStore (state, payload) {
     state.userStore = payload
    },
    setCoreAppBar (state, payload) {
        state.coreAppBar = payload
    },
    setCoreSettings (state, payload) {
        state.coreSettings = payload
    },
    setCoreDrawer (state, payload) {
        state.coreDrawer = payload
    },
    setCoreFooter (state, payload) {
        state.coreFooter = payload
    },
    setBlankSlate (state, payload) {
        state.coreAppBar = payload
        state.coreSettings = payload
        state.coreDrawer = payload
        state.coreFooter = payload
    },
    setActiveClient (state, payload) {
    state.activeClient = payload
  },
    setPackages (state, payload) {
    state.subPackages = payload
  },
  setFacilityCategories (state, payload) {
    state.facilityCategories = payload
  },
  setClassCategories (state, payload) {
    state.classCategories = payload
  },
  setTrainerList (state, payload) {
    state.trainerList = payload
  },
  setClientList (state, payload) {
    state.clientList = payload
  },
  setClassList (state, payload) {
    state.classList = payload
  },
  setFacilityList (state, payload) {
    state.facilityList = payload
  },
  setBranchList (state, payload) {
    state.branchList = payload
  },
}
