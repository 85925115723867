import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import * as firebase from 'firebase'
// import * as Cookies from 'js-cookie';
// import createPersistedState from 'vuex-persistedstate'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import config from '../config'

Vue.use(Vuex)
Vue.use(axios)
export const store = new Vuex.Store({
    state: {
      config: config,
      userData: {},
      userStore: '',
      snacky: {
            n: 'Message',
            t: 'Success',
            c: 'green darken-3',
        },
      barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
      barImage: 'https://res.cloudinary.com/fitrip/image/upload/v1619480019/side-1_diecie.jpg',
      drawer: null,
      coreSettings: false,
      coreAppBar: true,
      coreDrawer: true,
      coreFooter: true,
      activeClient: {},
      subPackages: [],
      facilityCategories: [],
      classCategories: [],
      trainerList: [],
      clientList: [],
      classList: [],
      facilityList: [],
      branchList: [],
    },
    mutations,
    actions,
    getters,
})
