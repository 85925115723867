// const BaseUrl = 'http://localhost:9050/api/v1'
const BaseUrl = 'http://wellness.fitripgh.com:9050/api/v1'

const host = BaseUrl.substring(0, BaseUrl.length - 4)

const config = {
  locale: 'en-US',
  url: BaseUrl,
  host: host,
    api:
        {
                me: BaseUrl + '/me',
                packages: BaseUrl + '/packages',
                facilities: BaseUrl + '/facilities',
                companies: BaseUrl + '/companies',
                classes: BaseUrl + '/classes',
                bookings: BaseUrl + '/bookings',
                schedules: BaseUrl + '/schedules',
                classSchedules: BaseUrl + '/schedules/classes',
                facilityCategory: BaseUrl + '/facility-categories',
                classCategory: BaseUrl + '/class-categories',
                login: BaseUrl + '/auth/login',
                changePassword: BaseUrl + '/auth/password/change',
                forgotPassword: BaseUrl + '/auth/password/forgot',
                staff: BaseUrl + '/users/register/staff',
                getStaff: BaseUrl + '/users/list/staff',
                clients: BaseUrl + '/users/register/clients',
                searchClients: BaseUrl + '/users/clients/search',
                getClients: BaseUrl + '/users/list/clients',
                subscription: BaseUrl + '/subscriptions',
                getClientSubscription: BaseUrl + '/subscriptions/clients',
                users: BaseUrl + '/users',
                checkins: BaseUrl + '/checkins',
                checkinLogs: BaseUrl + '/checkinlogs',
        },
    messages:
        {
            networkError: `There was a network error while processing your request.
                       Make sure you have network connection and try again. If the problem persists please contact IT Service Desk`,
            internalServerError: 'An application error occurred while processing  you request. If the problem persists please contact IT Service Desk ',
        },
}

global.config = config

export default config
