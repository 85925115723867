// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import config from './config.js'
import App from './App.vue'
import moment from 'moment'
import router from './router'
import { store } from './store/store'
import axios from 'axios'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import Mixins from './mixins'
import i18n from './i18n'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import SweetAlertIcons from 'vue-sweetalert-icons'

Vue.use(axios)
Vue.use(SweetAlertIcons)
Vue.use(VueTelInputVuetify, {
  vuetify,
})
Vue.mixin(Mixins)

Vue.prototype.$http = axios
axios.defaults.baseURL = config.apiBaseUrl
axios.defaults.headers.get.Accept = 'application/json'
const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  axios.defaults.headers.common.id_token = token
}
Vue.prototype.moment = moment
Vue.config.productionTip = false

Vue.mixin({
  computed: {
    data () {
      return {
         err: [],
      }
    },
    userData () {
      return this.$store.getters.getUserData
    },
    apiSet () {
      return this.$store.getters.getApi
    },
  },
  mounted () {
  },

  methods: {

  },

})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
