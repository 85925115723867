/* eslint-disable */
const mixin = {
    methods:
        {
            soundMsg () {
                const aSound = document.createElement('audio')
                aSound.setAttribute('src', 'https://res.cloudinary.com/fitrip/video/upload/v1620187634/msg_kp9cue.wav')
                aSound.play()
            },
            soundCbk () {
                const aSound = document.createElement('audio')
                aSound.setAttribute('src', 'https://res.cloudinary.com/fitrip/video/upload/v1620187634/cbk_fn2sxi.wav')
                aSound.play()
            },
            soundBook () {
                const aSound = document.createElement('audio')
                aSound.setAttribute('src', 'https://res.cloudinary.com/fitrip/video/upload/v1620187634/book_vc48r1.wav')
                aSound.play()
            },
            notifyMe (app, msg) {
                if (!window.Notification) {
                    console.log('Browser does not support notifications.')
                } else {
                    // check if permission is already granted
                    if (Notification.permission === 'granted') {
                        // show notification here
                      // eslint-disable-next-line no-unused-vars
                        var notify = new Notification(app, {
                            body: msg,
                            icon: '/static/img/medingin_logo.png',
                        })
                    } else {
                      notify = new Notification(app, {
                        body: msg,
                        icon: '/static/img/medingin_logo.png',
                      })
                        // request permission from user
                        Notification.requestPermission().then(function (p) {
                            if (p === 'granted') {
                                // show notification here
                                notify = new Notification(app, {
                                    body: msg,
                                    icon: '/static/img/medingin_logo.png',
                                })
                            } else {
                                console.log('User blocked notifications.')
                            }
                        }).catch(function (err) {
                            notify = new Notification(app, {
                                body: msg,
                                icon: '/static/img/medingin_logo.png',
                            })
                            console.error(err)
                        })
                    }
                }
            },
        },
}
export default mixin
